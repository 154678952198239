import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Divider,
  HStack,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import {Employee} from "qs-api";
import {Fragment} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {AiOutlineSafetyCertificate} from "react-icons/ai";
import {BiBell} from "react-icons/bi";
import {BsPerson} from "react-icons/bs";
import {FiChevronRight, FiSettings} from "react-icons/fi";
import ContractorInfo from "./Components/ContractorInfo";
import EmployementInfo from "./Components/EmployementInfo";
import ModuleAccess from "./Components/ModuleAccess";
import ProfileInfo from "./Components/ProfileInfo";

export default function Profile() {
  const modules = ["AUDITOR", "MAINTENANCE", "TIMESHEET"];
  const form = useForm<Employee.IEmployee>({
    defaultValues: {
      // Contractor Section
      contractor: false,
      contractorAbn: "",
      contractorAcn: "",
      contractorCompanyAddress: "",
      contractorCompanyName: "",
      contractorContactNumber: "",
      contractorEmail: "",
      contractorGstRegistered: false,
      contractorName: "",

      // Personal Section
      email: "",
      firstName: "",
      lastName: "",
      contactNumber: "",
      address: "",
      preferredName: "",
      dateOfBirth: "",
      phoneNumber: "",

      // Employement Section
      abn: "",
      endDate: 0,
      fullyTrained: false,
      position: "",
      // registered: false,
      startDate: 0,
      tfn: "",

      // Access Section
      access: "ROLE_EMPLOYEE",
      modules: [],
    },
  });

  const tabs: Array<{
    label?: string;
    icon: any;
    desc: string;
  }> = [
    {
      label: "Profile Information",
      icon: BsPerson,
      desc: "About your personal information",
    },
    {
      label: "Employement Information",
      icon: FiSettings,
      desc: "Manage Employment Information",
    },
    {
      label: "Contractor Information",
      icon: AiOutlineSafetyCertificate,
      desc: "Manage Contractor Information",
    },
    {
      label: "Modules Access",
      icon: BiBell,
      desc: "Manage QSComply Modules Access",
    },
  ];

  const tabsOrientation: "horizontal" | "vertical" | undefined =
    useBreakpointValue({
      xs: "horizontal",
      md: "vertical",
    });

  return (
    <FormProvider {...form}>
      <Stack pb="20px">
        <Stack p="20px" spacing={0}>
          <Breadcrumb
            spacing="8px"
            separator={<FiChevronRight color="gray.500" />}
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="#" fontSize={"13px"}>
                Pages
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <BreadcrumbLink href="#" fontSize={"13px"}>
                User
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbItem isCurrentPage>
              <BreadcrumbLink href="#" fontSize={"13px"}>
                Profile Settings
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Text fontSize="28px" fontWeight={500} color="textcolors.primary">
            Profile Settings
          </Text>
        </Stack>
        <Tabs orientation={tabsOrientation} px="5px">
          <TabList
            minW={{md: "310px", xs: "100%"}}
            borderWidth="1px"
            borderColor="borders.primary"
            position="sticky"
            top={0}
            bgColor="white"
            zIndex={"1"}
          >
            {tabs.map((menu, i) => {
              return (
                <Fragment key={i}>
                  <Tab
                    borderWidth="0px"
                    p="15px"
                    _hover={{backgroundColor: "gray.100"}}
                    margin="0px"
                    w="100%"
                  >
                    <HStack
                      w="100%"
                      align="top"
                      justify={{xs: "center", md: "flex-start"}}
                    >
                      <Icon
                        as={menu.icon}
                        boxSize={22}
                        alignSelf={{
                          xs: "center",
                          md: undefined,
                        }}
                      />
                      <VStack
                        flex={1}
                        align="start"
                        spacing="2px"
                        display={{
                          md: "flex",
                          xs: "none",
                        }}
                      >
                        <Text
                          fontSize="14px"
                          fontWeight={500}
                          // color="#06072d"
                          _selected={{
                            color: "primary",
                          }}
                          lineHeight={1.2}
                        >
                          {menu.label}
                        </Text>
                        <Text
                          fontSize="12px"
                          fontWeight={400}
                          color="smalltext.primary"
                          lineHeight={1.5}
                        >
                          {menu.desc}
                        </Text>
                      </VStack>
                    </HStack>
                  </Tab>
                  <Divider
                    display={{
                      xs: "none",
                      md: "block",
                    }}
                    borderColor="borders.primary"
                  />
                </Fragment>
              );
            })}
          </TabList>

          <TabPanels pl={{xs: "0px", md: "10px"}}>
            <TabPanel p={0}>
              <ProfileInfo />
            </TabPanel>
            <TabPanel p={0}>
              <EmployementInfo />
            </TabPanel>
            <TabPanel p={0}>
              <ContractorInfo />
            </TabPanel>
            <TabPanel p={0}>
              <ModuleAccess />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Stack>
    </FormProvider>
  );
}
