import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";

import {Auth} from "qs-api";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {IoMdEye, IoMdEyeOff} from "react-icons/io";
import {useNavigate, useSearchParams} from "react-router-dom";
import Input from "../../components/Input/Input";
import useUser from "../../hooks/user";
import LoginBanner from "./../../assets/svg/login-banner.jpeg";
import QSLogo from "./../../assets/svg/logo-horizontal.svg";
export default function Login() {
  const [searchParams, setSearchParams] = useSearchParams();
  const {setUser} = useUser();
  const navigate = useNavigate();
  const {control, handleSubmit, getValues, setValue} =
    useForm<Auth.LoginPayload>({
      defaultValues: {
        username: "",
        password: "",
        // token: searchParams.get("newEmployeeToken") ?? "",
      },
    });
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toast = useToast({
    position: "top",
  });

  useEffect(() => {
    const newEmployeeToken = searchParams.get("newEmployeeToken");
    const confirmationToken = searchParams.get("confirmationToken");
    if (confirmationToken) {
      Auth.ConfirmEmail(
        confirmationToken,
        (message) => {
          toast({
            title: "Success",
            description: message,
            status: "success",
          });
          setSearchParams((params) => {
            params.delete("confirmationToken");
            return params;
          });
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }

    if (newEmployeeToken) {
      setValue("token", newEmployeeToken);
    }
  }, []);

  return (
    <Box h={"100vh"}>
      <Stack spacing={0} justifyContent="space-between" isInline h="100%">
        <Box
          boxShadow="2xl"
          zIndex={1}
          p={30}
          minW={{xs: "100%", md: 440}}
          overflow="auto"
        >
          <VStack justify="space-between" h="100%">
            <Box w="100%">
              <Center>
                <Image src={QSLogo} mb={50} maxW={300} resize="both" />
              </Center>
              <Box mb={10}>
                <Text fontSize="26" fontWeight="400">
                  Welcome back!
                </Text>
                <Text color="GrayText" fontSize={20}>
                  Please signin to continue.
                </Text>
              </Box>
              <Controller
                rules={{
                  required: {
                    value: true,
                    message: "Email address is required",
                  },
                  pattern: {
                    value:
                      /^([0-9a-zA-Z]([-\.\+\_\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i,
                    message: "Enter a valid email",
                  },
                }}
                control={control}
                name="username"
                render={({field, fieldState: {error}}) => (
                  <Input
                    withValidation
                    validationMessage={error?.message}
                    wrapper={{flex: 1}}
                    label="Email Address"
                    input={{
                      ...field,
                      isInvalid: error !== undefined,
                      type: "email",
                    }}
                  />
                )}
              />
              <Controller
                rules={{
                  required: {value: true, message: "Password is required"},
                }}
                control={control}
                name="password"
                render={({field, fieldState: {error}}) => (
                  <Input
                    withValidation
                    validationMessage={error?.message}
                    wrapper={{flex: 1}}
                    label="Password"
                    input={{
                      ...field,
                      type: isPasswordVisible ? "text" : "password",
                      isInvalid: error !== undefined,
                    }}
                    right={
                      <IconButton
                        variant="ghost"
                        aria-label="password-icon"
                        icon={isPasswordVisible ? <IoMdEyeOff /> : <IoMdEye />}
                        onClick={() => setIsPasswordVisible((prev) => !prev)}
                      />
                    }
                  />
                )}
              />
              <Box justifyContent="flex-end" display="flex" marginBottom={5}>
                <Link
                  onClick={() => {
                    navigate("/forgotpassword");
                  }}
                  fontSize={12}
                >
                  Forgot Password?
                </Link>
              </Box>

              <HStack w="100%">
                <Button
                  isLoading={isLoading}
                  flex={1}
                  onClick={handleSubmit(
                    (data) => {
                      setIsLoading(true);
                      Auth.Login(
                        data,
                        (user) => {
                          Auth.SaveUserInBrowser(user)
                            .then(() => {
                              setUser(user);
                              const returnURL = searchParams.get("returnURL");
                              const requestFrom =
                                searchParams.get("requestFrom");
                              if (requestFrom === "mobile") {
                                window.close();
                                window.open(
                                  `qscomply://Login/${user.token}`,
                                  "_blank"
                                );
                              } else if (!returnURL) {
                                navigate("/app/businesses");
                              } else if (!user.defaultBusiness) {
                                navigate(`/app/businesses/?goto=${returnURL}`);
                              }
                            })
                            .catch((e) => {
                              toast({
                                status: "error",
                                title: "Error",
                                description: "Couldn't login",
                              });
                            });
                        },
                        (error) => {
                          toast({
                            title: "Error",
                            description: error,
                            status: "error",
                          });
                        },
                        () => {
                          setIsLoading(false);
                        }
                      );
                    },
                    (error) => {}
                  )}
                >
                  SIGN IN
                </Button>
                <Button
                  flex={1}
                  onClick={() => {
                    navigate("/register", {
                      state: getValues(),
                    });
                  }}
                >
                  SIGN UP
                </Button>
              </HStack>
            </Box>
            <Box mt="auto" mb={0}>
              <Text fontSize={13}>
                By signing in, you agree to our <Link>Terms of Use</Link> and{" "}
                <Link href="https://www.qscomply.com.au/privacy-policy">
                  Privacy Policy
                </Link>
              </Text>
            </Box>
          </VStack>
        </Box>
        <Box
          display={{
            xs: "none",
            md: "flex",
          }}
          flex={1}
          h="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Image src={LoginBanner} objectFit="cover" h="100%" w="100%" />
        </Box>
      </Stack>
    </Box>
  );
}
