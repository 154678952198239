export default function convertFileToBase64(
  file: File,
  maxFileSizeInMbs?: number
): Promise<{base64String: string; fileName: string}> {
  return new Promise((resolve, reject) => {
    if (maxFileSizeInMbs && file.size > maxFileSizeInMbs) {
      reject(
        `File size exceeds ${(maxFileSizeInMbs / 1000000).toPrecision(
          1
        )} MB\nUpload file size is ${file.size / 1000000} MB`
      );
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result?.toString().split(",")[1];
        if (base64String) {
          resolve({base64String, fileName: file.name});
        } else {
          reject("Failed to convert file to base64");
        }
      };
      reader.onerror = () => {
        reject(reader.error);
      };
    }
  });
}
