import {
  Box,
  Button,
  Center,
  Icon,
  IconButton,
  Image,
  Stack,
  Text,
  useBoolean,
  useToast,
  VStack,
} from "@chakra-ui/react";

import { Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { SiAdguard } from "react-icons/si";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Auth } from "qs-api";
import Input from "../../components/Input/Input";
import LoginBanner from "./../../assets/svg/login-banner.jpeg";
import QSLogo from "./../../assets/svg/logo-horizontal.svg";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const { control, handleSubmit, getValues } = useForm({
    defaultValues: { email: "" },
  });

  const [APIProcessing, setAPIProcessing] = useBoolean();
  const [isEmailSent, setIsEmailSent] = useBoolean();
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("resetToken");
  const toast = useToast({
    position: "top",
  });

  function PasswordResetForm() {
    return (
      <Fragment>
        <Controller
          rules={{
            required: {
              value: true,
              message: "Email address is required",
            },
            pattern: {
              value:
                /^([0-9a-zA-Z]([-\.\+\_\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i,
              message: "Enter a valid email",
            },
          }}
          control={control}
          name="email"
          render={({ field, fieldState: { error } }) => (
            <Input
              withValidation
              validationMessage={error?.message}
              wrapper={{ flex: 1 }}
              label="Email Address"
              input={{
                ...field,
                isInvalid: error !== undefined,
                type: "email",
              }}
            />
          )}
        />
        <Button
          flex={1}
          w={"100%"}
          mt={"2"}
          isLoading={APIProcessing}
          onClick={handleSubmit(
            (data) => {
              setAPIProcessing.on();
              Auth.InitPasswordReset(
                data.email,
                (message) => {
                  setAPIProcessing.off();
                  setIsEmailSent.on();
                },
                (error) => {
                  setAPIProcessing.off();
                  toast({
                    position: "top",
                    title: "Unable to send verification code",
                    description: error,
                  });
                }
              );
            },
            (error) => { }
          )}
        >
          SEND CODE VERIFICATION
        </Button>
      </Fragment>
    );
  }

  function EmailSent() {
    return (
      <VStack>
        <Icon as={MdOutlineMarkEmailRead} boxSize={150} color="primary.900" />
        <Text textAlign="center">
          Verification Code has been sent to your registered email
        </Text>
      </VStack>
    );
  }
  return (
    <Box h="100vh">
      <Stack spacing={0} justifyContent={"space-between"} isInline h="100%">
        <Box
          display={{
            xs: "none",
            md: "flex",
          }}
          flex={1}
          justifyContent="center"
          alignItems="center"
          h="100%"
        >
          <Image src={LoginBanner} objectFit="cover" h="100%" w="100%" />
        </Box>
        <Box
          shadow={"2xl"}
          zIndex={1}
          p={30}
          w={{ xs: "100%", md: 440 }}
          h="100%"
          overflow="auto"
        >
          <VStack justify="space-between">
            <Box w={"100%"}>
              <Center>
                <Image src={QSLogo} mb={50} maxW={300} resize="both" />
              </Center>
              <Box mb={10}>
                <Text fontSize="26" fontWeight="400">
                  Reset Your Password
                </Text>
                <Text color="GrayText" fontSize={20} maxW={"400"}>
                  {resetToken
                    ? "Enter Verification code and new password"
                    : "Enter your email address and we will send you a link to reset your password"}
                </Text>
              </Box>
              {resetToken ? (
                <ResetPasswordForm />
              ) : isEmailSent ? (
                <EmailSent />
              ) : (
                <PasswordResetForm />
              )}
              <Button
                flex={1}
                w={"100%"}
                mt={"5"}
                isLoading={APIProcessing}
                colorScheme="primary"
                variant={"outline"}
                onClick={() => {
                  navigate("/", {
                    state: getValues(),
                  });
                }}
              >
                BACK TO LOGIN
              </Button>
            </Box>
          </VStack>
        </Box>
      </Stack>
    </Box>
  );
}

function ResetPasswordForm() {
  const [searchParams] = useSearchParams();
  const resetToken = searchParams.get("resetToken");
  const { control, handleSubmit, formState, getValues } = useForm({
    defaultValues: {
      token: resetToken ?? "",
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
  });
  const [passwordVisible, setPasswordVisible] = useBoolean();
  const [isResettingPassword, setIsResettingPassword] = useBoolean();
  const [isPasswordReset, setIsPasswordReset] = useBoolean(false);
  const toast = useToast({
    position: "top",
  });

  if (isPasswordReset) {
    return (
      <VStack>
        <Icon as={SiAdguard} boxSize={150} color="primary.500" />
        <Text fontWeight="bold">Password has been reset successfully!</Text>
      </VStack>
    );
  } else {
    return (
      <Box>
        <Box display='none'>
          <Controller
            rules={{
              required: {
                value: true,
                message: "Verification Code is required",
              },
            }}
            control={control}
            name="token"
            render={({ field, fieldState: { error } }) => (
              <Input
                withValidation
                validationMessage={error?.message}
                wrapper={{ flex: 1 }}
                label="Verification Code"
                input={{
                  ...field,

                  isInvalid: error !== undefined,
                }}
              />
            )}
          />
        </Box>
        <Controller
          rules={{
            required: { value: true, message: "Password is required" },
            pattern: {
              value: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
              message:
                "Password must contain atleast 1 upper and lower case letter and 1 number or special character",
            },
            minLength: {
              value: 6,
              message: "Password atleast 6 character long",
            },
          }}
          control={control}
          name="password"
          render={({ field, fieldState: { error } }) => (
            <Input
              withValidation
              validationMessage={error?.message}
              wrapper={{ flex: 1 }}
              label="Password"
              input={{
                ...field,
                type: passwordVisible ? "text" : "password",
                isInvalid: error !== undefined,
              }}
              right={
                <IconButton
                  variant="ghost"
                  aria-label="password-icon"
                  icon={passwordVisible ? <IoMdEyeOff /> : <IoMdEye />}
                  onClick={setPasswordVisible.toggle}
                />
              }
            />
          )}
        />
        <Controller
          rules={{
            required: { value: true, message: "Password is required" },
            pattern: {
              value: /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
              message:
                "Password must contain atleast 1 upper and lower case letter and 1 number or special character",
            },
            minLength: {
              value: 6,
              message: "Password atleast 6 character long",
            },
            validate: (value) =>
              getValues("password") === value
                ? true
                : "Password does not match",
          }}
          control={control}
          name="confirmPassword"
          render={({ field, fieldState: { error } }) => (
            <Input
              withValidation
              validationMessage={error?.message}
              wrapper={{ flex: 1 }}
              label="Confirm Password"
              input={{
                ...field,
                type: passwordVisible ? "text" : "password",
                isInvalid: error !== undefined,
              }}
              right={
                <IconButton
                  variant="ghost"
                  aria-label="password-icon"
                  icon={passwordVisible ? <IoMdEyeOff /> : <IoMdEye />}
                  onClick={setPasswordVisible.toggle}
                />
              }
            />
          )}
        />
        <Button
          flex={1}
          w={"100%"}
          mt={"2"}
          disabled={!formState.isValid}
          isLoading={isResettingPassword}
          onClick={handleSubmit(
            (data) => {
              setIsResettingPassword.on();
              Auth.ResetPassword(
                {
                  password: data.password,
                  token: resetToken!,
                },
                (message) => {
                  setIsResettingPassword.off();

                  setIsPasswordReset.on();
                },
                (error) => {
                  setIsResettingPassword.off();
                  toast({
                    status: "error",
                    title: "Unable to Reset Password",
                    description: error,
                  });
                }
              );
            },
            (error) => { }
          )}
        >
          Reset Password
        </Button>
      </Box>
    );
  }
}
