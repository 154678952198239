import {
  Box,
  Button,
  Center,
  IconButton,
  Image,
  Stack,
  Text,
  VStack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Induction } from "../api/Induction";
import Input from "../components/Input/Input";
import LoginBanner from "./../assets/svg/login-banner.svg";
import QSLogo from "./../assets/svg/logo-horizontal.svg";
export default function VerifyOtp() {
  const form = useForm<Induction.VerifyOtpForm>({
    defaultValues: {
      otp: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useBoolean();
  const navigate = useNavigate();
  const toast = useToast();
  return (
    <Box h={"100vh"}>
      <Stack spacing={0} justifyContent="space-between" isInline h="100%">
        <Box
          boxShadow={{
            sm: "none",
            lg: "2xl",
          }}
          zIndex={1}
          p={30}
          minW={{ sm: "100%", lg: window.screen.width / 2.5 }}
          overflow="auto"
        >
          <VStack justify="space-between" h="100%">
            <Box w="100%">
              <IconButton
                aria-label="back"
                icon={<IoArrowBackSharp size={24} />}
                onClick={() => navigate(-1)}
                variant="ghost"
                borderRadius={10}
                boxSize={10}
              />
              <Center>
                <Image src={QSLogo} mb={50} maxW={300} resize="both" />
              </Center>
              <Box mb={10}>
                <Text fontSize="26" fontWeight="400">
                  Verification Code
                </Text>
                <Text color="GrayText" fontSize={20}>
                  We have sent a verification code to your email address. Please
                  enter the code below.
                </Text>
              </Box>
              <Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Verification code is required",
                    },
                  }}
                  control={form.control}
                  name="otp"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      withValidation
                      validationMessage={error?.message}
                      wrapper={{ flex: 1 }}
                      label="Code"
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        type: "text",
                      }}
                    />
                  )}
                />
              </Stack>

              <Button
                w="100%"
                isLoading={isSubmitting}
                onClick={form.handleSubmit((data) => {
                  setIsSubmitting.on();
                  Induction.PostVerifyOtp(
                    data,
                    (res) => {
                      //   toast({
                      //     title: "Request Submitted",
                      //     description:
                      //       "Your request has been submitted successfully",
                      //     status: "success",
                      //     duration: 5000,
                      //     isClosable: true,
                      //   });
                      if (res?.message === "Invalid or expired OTP") {
                        toast({
                          title: "Request Failed",
                          description: "Invalid or expired OTP",
                          status: "error",
                          duration: 5000,
                          isClosable: true,
                        });
                        setIsSubmitting.off();
                        return;
                      }
                      navigate("/success");
                      setIsSubmitting.off();
                    },
                    (err) => {
                      toast({
                        title: "Request Failed",
                        description: err,
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });

                      setIsSubmitting.off();
                    }
                  );
                })}
              >
                Verify
              </Button>
            </Box>
          </VStack>
        </Box>
        <Box
          display={{
            sm: "none",
            lg: "flex",
          }}
          flex={1}
          justifyContent="center"
          alignItems="center"
          h="100%"
        >
          <Image src={LoginBanner} w="100%" objectFit="cover" h="100%" />
        </Box>
      </Stack>
    </Box>
  );
}
