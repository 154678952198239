import {AbsoluteCenter, Box, Spinner, useToast} from "@chakra-ui/react";

import {Auth} from "qs-api";
import {isTokenExpired} from "qsui";
import {useEffect} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import useUser from "../../hooks/user";

export default function Authentication() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const toast = useToast({
    position: "top",
    status: "error",
  });
  const {user} = useUser();

  useEffect(() => {
    const returnURL = searchParams.get("returnURL");

    function goToReturnURL(token?: string) {
      if (returnURL) {
        if (returnURL === window.location.origin) {
          navigate("/");
        } else {
          window.location.href = `${returnURL}?token=${token}`;
        }
      } else navigate("/app/businesses");
    }

    if (!user) {
      navigate(`/?returnURL=${returnURL}`);
    } else {
      const jwtPayload = JSON.parse(window.atob(user.token.split(".")[1]));

      if (isTokenExpired(user.token)) {
        Auth.GenerateNewToken(
          user.refreshToken,
          (data) => {
            Auth.SaveUserInBrowser({
              ...user,
              token: data.accessToken,
              refreshToken: data.refreshToken,
            });
            goToReturnURL(data.accessToken);
          },
          (error) => {
            toast({
              title: "Session Expired",
              description: error,
            });
            // navigate("/");
          }
        );
      } else {
        goToReturnURL(user.token);
      }
    }
  }, []);
  return (
    <Box h="100vh" w="100vw">
      <AbsoluteCenter>
        <Spinner size="xl" />
      </AbsoluteCenter>
    </Box>
  );
}
