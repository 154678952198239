import {AbsoluteCenter, Box, Heading, HStack, Spinner} from "@chakra-ui/react";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

export default function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
    }, 2000);
  }, []);
  return (
    <Box w="100vw" h="100vh">
      <AbsoluteCenter>
        <HStack>
          <Spinner color="primary.500" size="xl" thickness="5px" />
          <Heading>Logging you out !</Heading>
        </HStack>
      </AbsoluteCenter>
    </Box>
  );
}
