import {
  Button,
  ButtonGroup,
  Container,
  Divider,
  HStack,
  Icon,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Spinner,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { Business } from "qs-api";
import { API, PageHeading } from "qsui";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { IoColorPalette } from "react-icons/io5";
import { MdAddBusiness, MdDelete } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import Branding from "./Components/Branding";
import General from "./Components/General";

export default function AddBusiness() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const form = useForm<Business.IBusiness>({
    defaultValues: {
      abn: "",
      address: "",
      businessName: "",
      website: "",
      acn: "",
      city: "",
      contactEmail: "",
      contactName: "",
      contactPhone: "",
      country: "",
      customBranding: "",
      displayGender: false,
      displayPreferredPronouns: false,
      id: "",
      leaveAllowBalanceView: false,
      leaveAllowUpdate: false,
      logoBase64: "",
      logoFileName: "",
      postCode: "",
    },
  });
  const tabs: Array<{
    label?: string;
    icon: any;
    desc: string;
  }> = [
    {
      label: "General",
      icon: MdAddBusiness,
      desc: "About your Business information",
    },
    {
      label: "Branding",
      icon: IoColorPalette,
      desc: "Manage Business theme",
    },
  ];
  const tabsOrientation: "horizontal" | "vertical" | undefined =
    useBreakpointValue({
      xs: "horizontal",
      lg: "vertical",
    });

  const { isOpen, onToggle, onClose } = useDisclosure();
  const [lastUpdate, setLastUpdate] = useState<number>(0);
  const toast = useToast({
    position: "top",
    isClosable: true,
  });
  useEffect(() => {
    if (id) {
      setIsLoading(true);
      Business.FetchBusinessDetails(
        id,
        (business) => {
          form.reset(business);

          setIsLoading(false);
        },
        (error) => {
          setIsLoading(false);
          toast({
            status: "error",
            title: "Error",
            description: error,
          });
          navigate(-1);
        }
      );
    }
  }, [lastUpdate]);
  return (
    <Container maxW="container.xl" my={10}>
      <Stack pb="20px" px={{ xs: "0px", md: "10px" }}>
        <HStack spacing="16px" justify="space-between">
          <HStack>
            <PageHeading>Business</PageHeading>
            <Popover isOpen={isOpen}>
              <PopoverTrigger>
                <IconButton
                  aria-label="back"
                  icon={<MdDelete size="16px" color="red" />}
                  borderRadius="50px"
                  _hover={{ backgroundColor: "red.100" }}
                  border="none"
                  variant="outline"
                  display={id ? "flex" : "none"}
                  onClick={onToggle}
                />
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverHeader fontWeight="semibold" py="4px">
                    Confirmation
                  </PopoverHeader>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    Are you sure you want to Delete this?
                  </PopoverBody>
                  <PopoverFooter
                    display="flex"
                    justifyContent="flex-end"
                    pt="16px"
                    border="0px"
                  >
                    <ButtonGroup size="xs" variant="solid" borderRadius="5px">
                      <Button onClick={onClose} borderRadius="5px">
                        No
                      </Button>
                      <Button
                        colorScheme="red"
                        borderRadius="5px"
                        onClick={() => {
                          const id = form.getValues("id");
                          if (id) {
                            Business.DeleteBusiness(
                              id,
                              () => {
                                navigate(-1);
                              },
                              (error) => {
                                toast({
                                  title: "Error",
                                  description: error,
                                  status: "error",
                                });
                              }
                            );
                          }
                        }}
                      >
                        Yes
                      </Button>
                    </ButtonGroup>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            </Popover>
          </HStack>

          <Button
            isLoading={isLoading}
            onClick={form.handleSubmit(
              (data) => {
                setIsLoading(true);
                if (id) {
                  Business.UpdateBusiness(
                    id,
                    API.getDirtyValues(form.formState.dirtyFields, data),

                    (res) => {
                      setLastUpdate(Date.now());
                      setIsLoading(false);
                      navigate(-1);
                    },
                    (error) => {
                      setIsLoading(false);
                      toast({
                        status: "error",
                        title: "Error",

                        description: error,
                      });
                    }
                  );
                } else {
                  Business.CreateBusiness(
                    data,
                    (res) => {
                      console.log(res);
                      setIsLoading(false);
                      navigate(-1);
                    },
                    (error) => {
                      setIsLoading(false);
                      toast({
                        status: "error",
                        title: "Error",

                        description: error,
                      });
                    }
                  );
                }
              },
              (error) => {
                const err = Object.entries(error)?.[0];
                toast({
                  status: "error",
                  description: err?.[1].message,
                });
              }
            )}
          >
            {id ? "Update" : "Create"}
          </Button>
        </HStack>

        <Tabs
          orientation={tabsOrientation}
          boxShadow={{ xs: "sm", md: "none" }}
        >
          <TabList
            minW={{ md: "25%", xs: "100%" }}
            borderWidth="1px"
            borderColor="border.accent"
            position="sticky"
            top={0}
            bgColor="white"
            zIndex={"1"}
            boxShadow={{ xs: "none", lg: "sm" }}
            _hover={{ boxShadow: "lg" }}
          >
            {tabs.map((menu, i) => {
              return (
                <Fragment key={i}>
                  <Tab
                    borderWidth="0px"
                    p="15px"
                    _hover={{ backgroundColor: "gray.100" }}
                    margin="0px"
                    w="100%"
                  >
                    <HStack
                      w="100%"
                      justify={{ xs: "center", lg: "flex-start" }}
                    >
                      <Icon
                        as={menu.icon}
                        boxSize={22}
                        alignSelf={{
                          xs: "center",
                          lg: "flex-start",
                        }}
                      />
                      <VStack
                        flex={1}
                        align="start"
                        spacing="2px"
                        display={{
                          lg: "flex",
                          xs: "none",
                        }}
                      >
                        <Text
                          fontSize="14px"
                          fontWeight={500}
                          // color="#06072d"
                          _selected={{
                            color: "primary",
                          }}
                          lineHeight={1.2}
                        >
                          {menu.label}
                        </Text>
                        <Text
                          fontSize="12px"
                          fontWeight={400}
                          color="smalltext.primary"
                          lineHeight={1.5}
                          noOfLines={1}
                          textAlign="start"
                        >
                          {menu.desc}
                        </Text>
                      </VStack>
                    </HStack>
                  </Tab>
                  <Divider
                    display={{
                      xs: "none",
                      lg: "block",
                    }}
                    borderColor="border.accent"
                  />
                </Fragment>
              );
            })}
          </TabList>
          <FormProvider {...form}>
            <TabPanels
              ml={{ xs: "0px", lg: "10px" }}
              _hover={{ boxShadow: "lg" }}
            >
              <TabPanel p={0}>
                {isLoading ? (
                  <Stack w="100%" h="30vh" justify="center" align="center">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="primary.500"
                      size="xl"
                    />
                  </Stack>
                ) : (
                  <General />
                )}
              </TabPanel>
              <TabPanel p={0}>
                {isLoading ? (
                  <Stack w="100%" h="30vh" justify="center" align="center">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="primary.500"
                      size="xl"
                    />
                  </Stack>
                ) : (
                  <Branding />
                )}
              </TabPanel>
            </TabPanels>
          </FormProvider>
        </Tabs>
      </Stack>
    </Container>
  );
}
