import {
  Button,
  ButtonGroup,
  Checkbox,
  Divider,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import Input from "../../../components/Input/Input";

export default function EmployementInfo() {
  const form = useFormContext();
  return (
    <Stack
      border="1px solid"
      borderColor="borders.primary"
      px={{ xs: "5px", md: "40px" }}
      py="35px"
      spacing={"16px"}
    >
      <Stack>
        <Text
          color="textcolors.primary"
          fontSize="14px"
          fontWeight={500}
          lineHeight={1.2}
        >
          EMPLOYEMENT INFORMATION
        </Text>
      </Stack>
      <Divider />
      <Stack maxW="80%" spacing="20px">
        <Stack>
          <HStack spacing={4}>
            <Stack flex={1}>
              <Controller
                control={form.control}
                name="abn"
                rules={{
                  required: {
                    value: true,
                    message: "This is required",
                  },
                }}
                render={({ field, fieldState }) => (
                  <Input
                    input={{
                      ...field,
                      variant: "outline",
                      isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                      type: "number",
                    }}
                    label="ABN #"
                    withValidation
                    validationMessage={fieldState.error?.message}
                  />
                )}
              />
            </Stack>
            <Stack flex={1}>
              <Controller
                control={form.control}
                name="tfn"
                rules={{
                  required: {
                    value: true,
                    message: "This is required",
                  },
                }}
                render={({ field, fieldState }) => (
                  <Input
                    input={{
                      ...field,
                      variant: "outline",
                      isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                      type: "number",
                    }}
                    label="TFN #"
                    withValidation
                    validationMessage={fieldState.error?.message}
                  />
                )}
              />
            </Stack>
          </HStack>

          <Text fontSize="11px" lineHeight={1.5} color="icons.primary">
            Your name may appear around here where you are mentioned. You can
            change or remove it at any time.
          </Text>
        </Stack>

        <Stack>
          <Controller
            control={form.control}
            name="position"
            rules={{
              required: {
                value: true,
                message: "This is required",
              },
            }}
            render={({ field, fieldState }) => (
              <Input
                input={{
                  ...field,
                  variant: "outline",
                  isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                  type: "text",
                }}
                label="Position"
                withValidation
                validationMessage={fieldState.error?.message}
              />
            )}
          />
        </Stack>
        <Stack spacing={4} direction={{ xs: "column", md: "row" }}>
          <Stack flex={1}>
            <Controller
              control={form.control}
              name="startDate"
              rules={{
                required: {
                  value: true,
                  message: "This is required",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  input={{
                    ...field,
                    variant: "outline",
                    isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                    type: "date",
                  }}
                  label="Start Date"
                  withValidation
                  validationMessage={fieldState.error?.message}
                />
              )}
            />
          </Stack>
          <Stack flex={1}>
            <Controller
              control={form.control}
              name="endDate"
              rules={{
                required: {
                  value: true,
                  message: "This is required",
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  input={{
                    ...field,
                    variant: "outline",
                    isInvalid: (fieldState.error?.message?.length ?? 0) > 0,
                    type: "date",
                  }}
                  label="End Date"
                  withValidation
                  validationMessage={fieldState.error?.message}
                />
              )}
            />
          </Stack>
        </Stack>

        <Stack>
          <Controller
            control={form.control}
            name="registered"
            render={({ field }) => <Checkbox {...field}> Registered</Checkbox>}
          />
        </Stack>
        <Stack>
          <Controller
            control={form.control}
            name="fullyTrained"
            render={({ field }) => (
              <Checkbox {...field}> Full Trained</Checkbox>
            )}
          />
        </Stack>

        <Text
          fontSize="13px"
          fontWeight={400}
          color="icons.primary"
          lineHeight={1.5}
        >
          All of the fields on this page are optional and can be deleted at any
          time, and by filling them out, you're giving us consent to share this
          data wherever your user profile appears.
        </Text>
      </Stack>
      <ButtonGroup pt="16px">
        <Button
          px="16px"
          py="7px"
          lineHeight={1.5}
          fontWeight={400}
          variant="solid"
        >
          Update Profile
        </Button>
        <Button
          px="16px"
          py="7px"
          lineHeight={1.5}
          fontWeight={400}
          variant="outline"
        >
          Reset Changes
        </Button>
      </ButtonGroup>
    </Stack>
  );
}
