import {Auth} from "qs-api";
import {isTokenExpired} from "qsui";
import React, {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

interface UserContextProps {
  setUser: React.Dispatch<React.SetStateAction<Auth.User | null>>;
  user: Auth.User | null;
}
export const UserContext = React.createContext<UserContextProps>({
  setUser: () => {},
  user: null,
});

export function UserProvider({children}: any) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [user, setUser] = useState<Auth.User | null>(Auth.GetLoggedInUser());

  useEffect(() => {
    const returnURL = searchParams.get("returnURL");
    const resetToken = searchParams.get("resetToken");
    const newEmployeeToken = searchParams.get("newEmployeeToken");
    const signUpToken = searchParams.get("signUpToken");

    if (resetToken) {
      navigate(`/forgotpassword?resetToken=${resetToken}`);
    } else if (newEmployeeToken || signUpToken) {
      if (newEmployeeToken) {
        navigate(`/register/?newEmployeeToken=${newEmployeeToken}`);
      }
      else {
        navigate(`/register/?signUpToken=${signUpToken}`);
      }
    } else if (user && isTokenExpired(user?.token)) {
      Auth.Logout();

      navigate(returnURL ? `/?returnURL=${returnURL}` : "/");
    } else if (returnURL && user) {
      navigate(`/auth/?returnURL=${returnURL}`);
    } else {
      // if (returnURL) {
      //   navigate(`/?returnURL=${returnURL}`);
      // }
    }
  }, [user]);
  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  );
}

export default function useUser() {
  return React.useContext<UserContextProps>(UserContext);
}
