import {
  Box,
  BoxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as CHInput,
  InputGroup,
  InputProps as CHInputProps,
  InputRightElement,
} from "@chakra-ui/react";
interface InputProps {
  wrapper?: BoxProps;
  input?: CHInputProps;
  label: string;
  withValidation?: boolean;
  validationMessage?: string;
  right?: any;
  placeholder?: string;
}
export default function Input({
  input,
  wrapper,
  label,
  withValidation,
  validationMessage,
  right,
  placeholder,
}: InputProps) {
  if (withValidation) {
    return (
      <FormControl isInvalid={input?.isInvalid} mb={2}>
        <FormLabel fontWeight="400" m={0}>
          {label}
        </FormLabel>
        <InputGroup>
          <CHInput
            variant="flushed"
            placeholder={placeholder}
            _focus={{
              boxShadow: "none",
            }}
            {...input}
          />
          {right && <InputRightElement>{right}</InputRightElement>}
        </InputGroup>
        {input?.isInvalid && (
          <FormErrorMessage>{validationMessage}</FormErrorMessage>
        )}
      </FormControl>
    );
  } else {
    return (
      <Box {...wrapper}>
        <label style={{fontSize: "14px", color: "#575d78"}}>{label}</label>

        <CHInput
          variant="flushed"
          mb={2}
          fontWeight="bold"
          placeholder={placeholder}
          _focus={{
            boxShadow: "none",
          }}
          {...input}
        />
      </Box>
    );
  }
}
