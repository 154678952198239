import {Stack} from "@chakra-ui/react";
import {Business} from "qs-api";
import {AddressInput, ChakraReactSelect, Input} from "qsui";
import TabTitle from "qsui/dist/cjs/components/tab-title";
import {Controller, useFormContext} from "react-hook-form";
import {useParams} from "react-router-dom";

export default function General() {
  const {control, setValue} = useFormContext<Business.IBusiness>();
  const {id} = useParams();
  return (
    <Stack
      border="1px solid"
      borderColor="border.accent"
      pb="20px"
      spacing={"16px"}
      boxShadow={{xs: "none", md: "sm"}}
      backgroundColor="white"
    >
      <Stack
        borderBottom="1px"
        borderColor="border.accent"
        px={{xs: "10px", md: "20px"}}
        py="12px"
      >
        <TabTitle>
          {id ? "Update Business details" : "Add New Business Details"}
        </TabTitle>
      </Stack>
      <Stack
        maxW={{sm: "100%", md: "80%"}}
        spacing="20px"
        px={{xs: "10px", md: "40px"}}
      >
        <Stack>
          {/* <Controller control={control} name="businessName" rules={{required: "Business Name is required",}} render={({field, fieldState: {invalid, error}})=>()} /> */}

          <Controller
            control={control}
            name="businessName"
            rules={{required: "Business Name is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,

                  variant: "outline",
                  type: "text",
                }}
                withValidation
                validationMessage={error?.message}
                label="Business Name"
              />
            )}
          />

          <Controller
            control={control}
            name="address"
            rules={{required: "Business Address is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <AddressInput
                onPlaceSelected={(place) => {
                  field.onChange(place.formatted_address);
                  setValue("postCode", place?.postcode ?? "");
                  setValue("city", place?.city ?? "");
                  setValue("country", place?.country ?? "");
                }}
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",
                  type: "text",
                }}
                withValidation
                validationMessage={error?.message}
                label="Address"
              />
            )}
          />
          <Controller
            control={control}
            name="abn"
            rules={{required: "Business ABN is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",

                  type: "number",
                }}
                withValidation
                validationMessage={error?.message}
                label="ABN"
              />
            )}
          />
          <Controller
            control={control}
            name="acn"
            rules={{required: "Business ACN is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",

                  type: "number",
                }}
                withValidation
                validationMessage={error?.message}
                label="ACN"
              />
            )}
          />
          <Controller
            control={control}
            name="website"
            rules={{required: "Business Website is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",

                  type: "text",
                }}
                withValidation
                validationMessage={error?.message}
                label="Business Website"
              />
            )}
          />
          <Controller
            control={control}
            name="contactName"
            rules={{required: "Business Contact Name is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",

                  type: "text",
                }}
                withValidation
                validationMessage={error?.message}
                label="Contact Name"
              />
            )}
          />
          <Controller
            control={control}
            name="contactEmail"
            rules={{
              required: "Business Contact Email is required",
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                message: "Please enter a valid email address",
              },
            }}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",

                  type: "text",
                }}
                withValidation
                validationMessage={error?.message}
                label="Contact Email"
              />
            )}
          />
          <Controller
            control={control}
            name="contactPhone"
            rules={{required: "Business Contact Phone is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",

                  type: "number",
                }}
                withValidation
                validationMessage={error?.message}
                label="Contact Phone"
              />
            )}
          />
          <Controller
            control={control}
            name="city"
            rules={{required: "Business City is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",

                  type: "text",
                }}
                withValidation
                validationMessage={error?.message}
                label="City/Suburb"
              />
            )}
          />
          <Controller
            control={control}
            name="postCode"
            rules={{required: "Business Postcode is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <Input
                input={{
                  ...field,
                  isInvalid: invalid,
                  variant: "outline",

                  type: "number",
                }}
                withValidation
                validationMessage={error?.message}
                label="Postcode"
              />
            )}
          />
          <Controller
            control={control}
            name="country"
            rules={{required: "Business Country is required"}}
            render={({field, fieldState: {invalid, error}}) => (
              <ChakraReactSelect
                label="Country"
                options={[
                  {label: "Australia", value: "Australia"},
                  {label: "Pakistan", value: "Pakistan"},
                ]}
                withValidation
                ErrorMessage={error?.message}
                isInvalid={invalid}
                value={
                  field.value
                    ? {
                        label: field.value,
                        value: field.value,
                      }
                    : undefined
                }
                onChange={(newValue: any) => {
                  field.onChange(newValue.value);
                }}
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
