import {
  Box,
  Button,
  Center,
  Checkbox,
  HStack,
  IconButton,
  Image,
  Link,
  Stack,
  Text,
  useBoolean,
  useToast,
  VStack,
} from "@chakra-ui/react";

import {Auth} from "qs-api";
import {AddressInput} from "qsui";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {IoMdEye, IoMdEyeOff} from "react-icons/io";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import Input from "../../components/Input/Input";
import LoginBanner from "./../../assets/svg/login-banner.jpeg";
import QSLogo from "./../../assets/svg/logo-horizontal.svg";
export default function Register() {
  const {state} = useLocation();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const newEmployeeToken = params.get("newEmployeeToken");
  const signUpToken = params.get("signUpToken");
  const {control, handleSubmit, reset} = useForm<
    Auth.RegisterPayload & {
      isAgreedWithTC: boolean;
    }
  >({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      isAgreedWithTC: false,
      phone: "",
      address: "",
      token: newEmployeeToken ?? signUpToken ?? "",
    },
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  useEffect(() => {
    if (newEmployeeToken || signUpToken) {
      Auth.FetchUnRegisteredUser(
        newEmployeeToken ?? signUpToken ?? "",
        (data) => {
          reset({...data, token: newEmployeeToken ?? signUpToken ?? ""});
        },
        (error) => {
          toast({
            title: "Error",
            description: error,
            status: "error",
          });
        }
      );
    }
  }, []);
  const [isRegistering, setIsRegistering] = useBoolean();

  const toast = useToast({
    position: "top",
  });

  const [searchParams] = useSearchParams();

  return (
    <Box h={"100vh"}>
      <Stack spacing={0} justifyContent="space-between" isInline h="100%">
        <Box
          boxShadow={{
            sm: "none",
            lg: "2xl",
          }}
          zIndex={1}
          p={30}
          minW={{sm: "100%", lg: window.screen.width / 2.5}}
          overflow="auto"
        >
          <VStack justify="space-between" h="100%">
            <Box w="100%">
              <Center>
                <Image src={QSLogo} mb={50} maxW={300} resize="both" />
              </Center>
              <Box mb={10}>
                <Text fontSize="26" fontWeight="400">
                  Create New Account!
                </Text>
                <Text color="GrayText" fontSize={20}>
                  It's free and only takes a minute.
                </Text>
              </Box>
              <Stack
                direction={{
                  md: "row",
                  xs: "column",
                }}
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "First name is required",
                    },
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters required",
                    },
                  }}
                  control={control}
                  name="firstName"
                  render={({field, fieldState: {error}}) => (
                    <Input
                      wrapper={{flex: 1}}
                      label="First Name"
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        type: "text",
                        isDisabled:
                          newEmployeeToken || signUpToken ? true : false,
                      }}
                      withValidation
                      validationMessage={error?.message}
                    />
                  )}
                />
                <Controller
                  rules={{
                    required: {value: true, message: "Last name is required"},
                    minLength: {
                      value: 3,
                      message: "Minimum 3 characters required",
                    },
                  }}
                  control={control}
                  name="lastName"
                  render={({field, fieldState: {error}}) => (
                    <Input
                      wrapper={{flex: 1}}
                      label="Last Name"
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        type: "text",
                        isDisabled:
                          newEmployeeToken || signUpToken ? true : false,
                      }}
                      withValidation
                      validationMessage={error?.message}
                    />
                  )}
                />
              </Stack>
              <Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Email address is required",
                    },
                    pattern: {
                      value:
                        /^([0-9a-zA-Z]([-\.\+\_\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i,
                      message: "Enter a valid email",
                    },
                  }}
                  control={control}
                  name="email"
                  render={({field, fieldState: {error}}) => (
                    <Input
                      withValidation
                      validationMessage={error?.message}
                      wrapper={{flex: 1}}
                      label="Email Address"
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        type: "email",
                        isDisabled:
                          newEmployeeToken || signUpToken ? true : false,
                      }}
                    />
                  )}
                />
                <Controller
                  rules={{
                    required: {value: true, message: "Password is required"},
                    pattern: {
                      value:
                        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                      message:
                        "Password must contain atleast 1 upper and lower case letter and 1 number or special character",
                    },
                    minLength: {
                      value: 6,
                      message: "Password atleast 6 character long",
                    },
                  }}
                  control={control}
                  name="password"
                  render={({field, fieldState: {error}}) => (
                    <Input
                      withValidation
                      validationMessage={error?.message}
                      wrapper={{flex: 1}}
                      label="Password"
                      input={{
                        ...field,
                        type: isPasswordVisible ? "text" : "password",
                        isInvalid: error !== undefined,
                      }}
                      right={
                        <IconButton
                          variant="ghost"
                          aria-label="password-icon"
                          icon={
                            isPasswordVisible ? <IoMdEyeOff /> : <IoMdEye />
                          }
                          onClick={() => setIsPasswordVisible((prev) => !prev)}
                        />
                      }
                    />
                  )}
                />
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Contact No. is required",
                    },
                  }}
                  control={control}
                  name="phone"
                  render={({field, fieldState: {error}}) => (
                    <Input
                      withValidation
                      validationMessage={error?.message}
                      wrapper={{flex: 1}}
                      label="Contact No."
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        type: "text",
                        isDisabled:
                          newEmployeeToken || signUpToken ? true : false,
                      }}
                    />
                  )}
                />
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Address is required",
                    },
                  }}
                  control={control}
                  name="address"
                  render={({field, fieldState: {error}}) => (
                    <AddressInput
                      onPlaceSelected={(place) => {
                        field.onChange(place.formatted_address);
                      }}
                      withValidation
                      validationMessage={error?.message}
                      wrapper={{flex: 1}}
                      label="Address"
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        isDisabled:
                          newEmployeeToken || signUpToken ? true : false,
                      }}
                    />
                  )}
                />
              </Stack>

              <HStack my={5}>
                <Controller
                  control={control}
                  name="isAgreedWithTC"
                  rules={{
                    required: {
                      value: true,
                      message: "Agree with T&Cs and privacy policy",
                    },
                  }}
                  render={({field: {value, ...field}, fieldState: {error}}) => (
                    <Checkbox
                      {...field}
                      isInvalid={error !== undefined}
                      isChecked={value}
                    />
                  )}
                />

                <Text fontSize={13}>
                  I have read and agree to your <Link>Terms of Use</Link> and{" "}
                  <Link href="https://www.qscomply.com.au/privacy-policy">
                    Privacy Policy
                  </Link>
                </Text>
              </HStack>

              <Button
                w="100%"
                isLoading={isRegistering}
                onClick={handleSubmit(
                  (data) => {
                    delete (data as any)["isAgreedWithTC"];
                    setIsRegistering.on();
                    Auth.Register(
                      data,
                      (message) => {
                        const requestFrom = searchParams.get("requestFrom");
                        if (requestFrom === "mobile") {
                          navigate("/?requestFrom=mobile");
                        } else {
                          navigate("/");
                        }
                      },
                      (error) => {
                        toast({
                          title: "Registeration Failed",
                          description: error,
                          status: "error",
                        });
                      },
                      () => {
                        setIsRegistering.off();
                      }
                    );
                  },
                  (error) => {
                    toast({
                      title: "Registeration Failed",
                      description: "Please fill all required field",
                      status: "error",
                    });
                  }
                )}
              >
                CREATE NEW ACCOUNT
              </Button>
            </Box>
            <Box mt="auto" mb={0}>
              <Text fontSize={13}>
                Already have an account?{" "}
                <Link
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Sign in
                </Link>
              </Text>
            </Box>
          </VStack>
        </Box>
        <Box
          display={{
            sm: "none",
            lg: "flex",
          }}
          flex={1}
          justifyContent="center"
          alignItems="center"
          h="100%"
        >
          <Image src={LoginBanner} w="100%" objectFit="cover" h="100%" />
        </Box>
      </Stack>
    </Box>
  );
}
