import axiosInstance from "axios";

axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;
// "http://localhost:8080";
// process.env.REACT_APP_API_URL;

axiosInstance.interceptors.request.use(function (config: any) {
  config.headers["Accept"] = "application/json";
  const data = localStorage.getItem("user");

  if (data) {
    const dataJSON = JSON.parse(data);
    if (config.headers) {
      config.headers.Authorization = `Bearer ${dataJSON.token}`;
      config.headers["Content-Type"] = "application/json";
    }
  }

  return config;
});

const axiosInstanceInduction = axiosInstance;
export default axiosInstanceInduction;
export { axiosInstanceInduction as axiosInstance };
