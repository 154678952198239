import { Box, Center, Image, Text, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import QSLogo from "./../assets/svg/logo-horizontal.svg";

export default function SuccessPage() {
  const navigate = useNavigate();
  return (
    <Box h={"100vh"}>
      <Box
        boxShadow={{
          sm: "none",
          lg: "2xl",
        }}
        zIndex={1}
        p={30}
        overflow="auto"
        h={"100%"}
      >
        <VStack justify="space-between" h="100%">
          <Box w="100%" h={"100%"} mt="200px">
            <Center>
              <Image src={QSLogo} mb={50} maxW={300} resize="both" />
            </Center>
            <Center>
              <Box mb={10}>
                <Text fontSize="26" fontWeight="400">
                  Success
                </Text>
                <Text color="GrayText" fontSize={20}>
                  We have sent the Induction Report to your email address.
                </Text>
              </Box>
            </Center>
          </Box>
        </VStack>
      </Box>
      {/* <Box
          display={{
            sm: "none",
            lg: "flex",
          }}
          flex={1}
          justifyContent="center"
          alignItems="center"
          h="100%"
        >
          <Image src={LoginBanner} w="100%" objectFit="cover" h="100%" />
        </Box> */}
    </Box>
  );
}
