import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Icon,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Fragment } from "react";
import { BsPerson } from "react-icons/bs";

export default function ModuleAccess() {
  const access: Array<{
    label: string;
    icon: any;
    decs: string;
  }> = [
    {
      label: "Admin",
      icon: BsPerson,
      decs: "Nemo enim ipsam voluptatem quia volup tas sit.",
    },
    {
      label: "Employee",
      icon: BsPerson,
      decs: "Nemo enim ipsam voluptatem quia volup tas sit.",
    },
    {
      label: "Contractor",
      icon: BsPerson,
      decs: "Nemo enim ipsam voluptatem quia volup tas sit.",
    },
  ];

  const moduleaccess: Array<{
    label: string;
    icon: any;
    decs: string;
  }> = [
    {
      label: "Auditor",
      icon: BsPerson,
      decs: "Nemo enim ipsam voluptatem quia volup tas sit.",
    },
    {
      label: "Maintenance",
      icon: BsPerson,
      decs: "Nemo enim ipsam voluptatem quia volup tas sit.",
    },
  ];
  return (
    <Stack>
      <Stack>
        <Text fontSize="21px" fontWeight={500} color="textcolors.primary">
          Access
        </Text>
        <SimpleGrid columns={{ xs: 2, lg: 4 }} spacing={2}>
          {access.map((menu, i) => {
            return (
              <Fragment key={i}>
                <Card borderWidth="1px" borderColor="borders.primary">
                  <CardHeader p="20px">
                    <menu.icon size={30} color="#1da5d4" />
                    <Text
                      my="10px"
                      fontSize="24px"
                      fontWeight={300}
                      color="primary.500"
                    >
                      {menu.label}
                    </Text>
                    <Text
                      fontSize="14px"
                      fontWeight={400}
                      color="icons.primary"
                      lineHeight={1.5}
                    >
                      {menu.decs}
                    </Text>
                  </CardHeader>
                  <CardFooter p="20px">
                    <VStack spacing="10px">
                      <Button
                        w="100%"
                        px="16px"
                        py="7px"
                        fontSize="13px"
                        fontWeight={500}
                        lineHeight={1.7}
                      >
                        CHOOSE
                      </Button>
                      <Text
                        fontSize="11px"
                        fontWeight={400}
                        color="icons.primary"
                        lineHeight={1.5}
                      >
                        Prices may vary depending on the options you need.
                      </Text>
                    </VStack>
                  </CardFooter>
                </Card>
              </Fragment>
            );
          })}

          <Card
            borderWidth="1px"
            borderColor="accent.500"
            bgGradient="linear(to-tl, accent.50, #fff)"
          >
            <CardHeader p="20px">
              <Icon as={BsPerson} boxSize={30} color="secondary.500" />
              {/* <BsPerson size={30} color="#1da5d4" /> */}
              <Text
                my="10px"
                fontSize="24px"
                fontWeight={500}
                color="secondary.500"
              >
                Client
              </Text>
              <Text
                fontSize="14px"
                fontWeight={400}
                color="icons.primary"
                lineHeight={1.5}
              >
                Nemo enim ipsam voluptatem quia volup tas sit.
              </Text>
            </CardHeader>
            <CardFooter p="20px">
              <VStack spacing="10px" w="100%">
                <Button
                  w="100%"
                  px="16px"
                  py="7px"
                  fontSize="13px"
                  fontWeight={500}
                  lineHeight={1.7}
                  colorScheme="accent"
                >
                  CHOOSE
                </Button>
                <Text
                  fontSize="11px"
                  fontWeight={400}
                  color="icons.primary"
                  lineHeight={1.5}
                >
                  Prices may vary depending on the options you need.
                </Text>
              </VStack>
            </CardFooter>
          </Card>
        </SimpleGrid>
      </Stack>
      <Stack>
        <Text fontSize="21px" fontWeight={500} color="textcolors.primary">
          Module Access
        </Text>
        <SimpleGrid columns={{ xs: 2, lg: 3 }} spacing={2}>
          {moduleaccess.map((menu, i) => {
            return (
              <Fragment key={i}>
                <Card borderWidth="1px" borderColor="borders.primary">
                  <CardHeader p="20px">
                    <menu.icon size={30} color="#1da5d4" />
                    <Text
                      my="10px"
                      fontSize="24px"
                      fontWeight={300}
                      color="primary.500"
                    >
                      {menu.label}
                    </Text>
                    <Text
                      fontSize="14px"
                      fontWeight={400}
                      color="icons.primary"
                      lineHeight={1.5}
                    >
                      {menu.decs}
                    </Text>
                  </CardHeader>
                  <CardFooter p="20px">
                    <VStack spacing="10px" w="100%" align="start">
                      <Button
                        // flex={1}
                        w="100%"
                        px="16px"
                        py="7px"
                        fontSize="13px"
                        fontWeight={500}
                        lineHeight={1.7}
                      >
                        CHOOSE
                      </Button>
                      <Text
                        fontSize="11px"
                        fontWeight={400}
                        color="icons.primary"
                        lineHeight={1.5}
                      >
                        Prices may vary depending on the options you need
                      </Text>
                    </VStack>
                  </CardFooter>
                </Card>
              </Fragment>
            );
          })}

          <Card
            borderWidth="1px"
            borderColor="accent.500"
            bgGradient="linear(to-tl, accent.50, #fff)"
            // backgroundColor="#f4f4ff"
            // backgroundImage="linear-gradient(to bottom, #fff 0%, #f4f4ff 100%)"
            // backgroundRepeat="repeat-x"
          >
            <CardHeader p="20px">
              <Icon as={BsPerson} boxSize={30} color="secondary.500" />
              {/* <BsPerson size={30} color="#1da5d4" /> */}
              <Text
                my="10px"
                fontSize="24px"
                fontWeight={500}
                color="secondary.500"
              >
                Timesheet
              </Text>
              <Text
                fontSize="14px"
                fontWeight={400}
                color="icons.primary"
                lineHeight={1.5}
              >
                Nemo enim ipsam voluptatem quia volup tas sit.
              </Text>
            </CardHeader>
            <CardFooter p="20px">
              <VStack spacing="10px" w="100%" align="start">
                <Button
                  w="100%"
                  px="16px"
                  py="7px"
                  fontSize="13px"
                  fontWeight={500}
                  lineHeight={1.7}
                  colorScheme="accent"
                  //   backgroundColor="blue"
                >
                  CHOOSE
                </Button>
                <Text
                  fontSize="11px"
                  fontWeight={400}
                  color="icons.primary"
                  lineHeight={1.5}
                >
                  Prices may vary depending on the options you need.
                </Text>
              </VStack>
            </CardFooter>
          </Card>
        </SimpleGrid>
      </Stack>
    </Stack>
  );
}
