import { ChakraProvider } from "@chakra-ui/react";
import { AuthenticationProvider, Header, LightTheme } from "qsui";
import { Fragment, useEffect } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import useUser, { UserProvider } from "../hooks/user";
// import lightTheme from "../theme/light";
import RedirectingPage from "../InductionRedirection/RedirectingPage";
import ReportRequestForm from "../InductionRedirection/ReportRequestForm";
import SuccessPage from "../InductionRedirection/SuccessPage";
import VerifyOtp from "../InductionRedirection/VerifyOtp";
import AddBusiness from "./AddBusiness/AddBusiness";
import Profile from "./Profile/Profile";
import Authentication from "./authentication/authentication";
import BusinessSelection from "./business-selection/business-selection";
import ForgotPassword from "./forgotpassword/forgotpassword";
import Login from "./login/login";
import Logout from "./logout/logout";
import Register from "./register/register";

export default function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <ChakraProvider theme={LightTheme}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/auth" element={<Authentication />} />
            <Route path="/app/*" element={<ProtectedRoutes />} />
            <Route path="/profile" element={<Profile />} />
            <Route
              path="/inductionReport/:id"
              element={<ReportRequestForm />}
            />
            <Route path="/verifyOtp" element={<VerifyOtp />} />
            <Route path="/success" element={<SuccessPage />} />
            <Route
              path="/redirecting/:templateId/:locationId"
              element={<RedirectingPage />}
            />
          </Routes>
        </ChakraProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

function ProtectedRoutes() {
  const { user } = useUser();
  useEffect(() => {
    console.log(process.env.REACT_APP_ENV);
  }, []);
  if (user) {
    return (
      <Fragment>
        <AuthenticationProvider>
          <Header />
          <Routes>
            <Route path="/businesses" element={<BusinessSelection />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/business/:id?" element={<AddBusiness />} />
            <Route path="/addbusiness" element={<AddBusiness />} />
          </Routes>
        </AuthenticationProvider>
      </Fragment>
    );
  } else {
    return <Navigate to="/" />;
  }
}
