import {
  Box,
  Button,
  Center,
  IconButton,
  Image,
  Stack,
  Text,
  VStack,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { IoArrowBackSharp } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { Induction } from "../api/Induction";
import Input from "../components/Input/Input";
import LoginBanner from "./../assets/svg/login-banner.svg";
import QSLogo from "./../assets/svg/logo-horizontal.svg";

export default function ReportRequestForm() {
  const form = useForm<Induction.RequestForm>({
    defaultValues: {
      inspectionId: undefined,
      fullName: "",
      email: "",
      phoneNumber: "",
    },
  });
  const [isSubmitting, setIsSubmitting] = useBoolean();
  const toast = useToast();
  const { id } = useParams();
  const navigate = useNavigate();
  return (
    <Box h={"100vh"}>
      <Stack spacing={0} justifyContent="space-between" isInline h="100%">
        <Box
          boxShadow={{
            sm: "none",
            lg: "2xl",
          }}
          zIndex={1}
          p={30}
          minW={{ sm: "100%", lg: window.screen.width / 2.5 }}
          overflow="auto"
        >
          <VStack justify="space-between" h="100%">
            <Box w="100%">
              <IconButton
                aria-label="back"
                icon={<IoArrowBackSharp size={24} />}
                onClick={() => navigate(-1)}
                variant="ghost"
                borderRadius={10}
                boxSize={10}
              />
              <Center>
                <Image src={QSLogo} mb={50} maxW={300} resize="both" />
              </Center>
              <Box mb={10}>
                <Text fontSize="26" fontWeight="400">
                  Create Request!
                </Text>
                <Text color="GrayText" fontSize={20}>
                  Verify your identity for viewing Induction Report!
                </Text>
              </Box>
              <Stack>
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Full Name is required",
                    },
                  }}
                  control={form.control}
                  name="fullName"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      withValidation
                      validationMessage={error?.message}
                      wrapper={{ flex: 1 }}
                      label="Full Name"
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        type: "text",
                      }}
                    />
                  )}
                />
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Email address is required",
                    },
                    pattern: {
                      value:
                        /^([0-9a-zA-Z]([-\.\+\_\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/i,
                      message: "Enter a valid email",
                    },
                  }}
                  control={form.control}
                  name="email"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      withValidation
                      validationMessage={error?.message}
                      wrapper={{ flex: 1 }}
                      label="Email Address"
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        type: "email",
                      }}
                    />
                  )}
                />

                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Phone No. is required",
                    },
                  }}
                  control={form.control}
                  name="phoneNumber"
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      withValidation
                      validationMessage={error?.message}
                      wrapper={{ flex: 1 }}
                      label="Phone No."
                      input={{
                        ...field,
                        isInvalid: error !== undefined,
                        type: "text",
                      }}
                    />
                  )}
                />
              </Stack>

              <Button
                w="100%"
                isLoading={isSubmitting}
                onClick={form.handleSubmit((data) => {
                  setIsSubmitting.on();
                  Induction.PostRequestForm(
                    { ...data, inspectionId: parseInt(id!) },
                    () => {
                      toast({
                        title: "Request Submitted",
                        description:
                          "Your request has been submitted successfully",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                      });
                      navigate("/verifyOtp");
                      setIsSubmitting.off();
                    },
                    () => {
                      toast({
                        title: "Request Failed",
                        description: "Your request could not be submitted",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                      navigate("/verifyOtp");
                      setIsSubmitting.off();
                    }
                  );
                })}
              >
                Submit Request
              </Button>
            </Box>
          </VStack>
        </Box>
        <Box
          display={{
            sm: "none",
            lg: "flex",
          }}
          flex={1}
          justifyContent="center"
          alignItems="center"
          h="100%"
        >
          <Image src={LoginBanner} w="100%" objectFit="cover" h="100%" />
        </Box>
      </Stack>
    </Box>
  );
}
