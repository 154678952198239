import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Business } from "qs-api";
import { Input } from "qsui";
import TabTitle from "qsui/dist/cjs/components/tab-title";
import { useRef } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import convertFileToBase64 from "../../../api/convertToBase64";
import Logo from "./../../../assets/svg/logo-horizontal.svg";

export default function Branding() {
  const { control, setValue } = useFormContext<Business.IBusiness>();
  const inputRef = useRef<HTMLInputElement>(null);
  const toast = useToast({
    position: "top",
  });
  const { id } = useParams();
  const watchedLogo = useWatch({ control, name: "logoBase64" });
  return (
    <Stack
      border="1px solid"
      borderColor="border.accent"
      pb="20px"
      spacing={"16px"}
      boxShadow={{ xs: "none", md: "sm" }}
      backgroundColor="white"
    >
      <Stack
        borderBottom="1px"
        borderColor="border.accent"
        px={{ xs: "10px", md: "20px" }}
        py="12px"
      >
        <TabTitle>
          {id ? "Update Business details" : "Add New Business Details"}
        </TabTitle>
      </Stack>
      <Stack
        maxW="100%"
        spacing="20px"
        px={{ xs: "10px", md: "40px" }}
        justify="center"
      >
        <Box
          backgroundColor="white"
          p="24px"
          textAlign="center"
          borderRadius="10px"
        >
          <Text fontSize="20px" fontWeight="bold" color="	#2e3192">
            Drag or drop your logo here
          </Text>
          <Text fontSize="12px" color="grey">
            Max size is 1MB: PNG, JPG, GIF supported
          </Text>
          <HStack mt="16px" px="24px" py="4px" justify="center">
            <Button
              borderRadius="50px"
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              Choose File
            </Button>
            {(watchedLogo?.length ?? 0) > 0 && (
              <Button
                colorScheme="red"
                borderRadius="50px"
                onClick={() => {
                  setValue("logoBase64", "");
                  setValue("logoFileName", "");
                }}
              >
                Clear Logo
              </Button>
            )}
          </HStack>
          <input
            ref={inputRef}
            accept=".png,.jpg,.jpeg"
            hidden
            type="file"
            onChange={(e) => {
              const files = e.target.files;
              if (files && files.length > 0) {
                const file = files[0];
                convertFileToBase64(file, 1 * 1024 * 1024)
                  .then((img) => {
                    setValue("logoBase64", img.base64String, {
                      shouldDirty: true,
                    });
                    setValue("logoFileName", img.fileName, {
                      shouldDirty: true,
                    });
                  })
                  .catch((error) => {
                    toast({
                      title: "Error",
                      status: "error",
                      description: error,
                    });
                  })
                  .finally(() => {
                    inputRef.current!.value = "";
                  });
              }
            }}
          />
        </Box>
        <Stack justifyContent="center" direction={{ xs: "column", md: "row" }}>
          <Stack align="center" px="12px">
            <Text color="grey" fontSize="15px">
              Current Logo
            </Text>
            <Box
              borderRadius="10px"
              backgroundColor="white"
              w="200px"
              h="75px"
              p="10px"
              borderColor="gray.200"
              borderWidth="1px"
            >
              <Controller
                control={control}
                name="logoPath"
                render={({ field }) => (
                  <Image
                    w="100%"
                    h="100%"
                    src={field.value ?? Logo}
                    objectFit="scale-down"
                  />
                )}
              />
            </Box>
          </Stack>
          <Stack align="center" px="12px">
            <Text color="grey" fontSize="15px">
              Preview
            </Text>
            <Box
              borderRadius="10px"
              backgroundColor="white"
              w="200px"
              h="75px"
              p="10px"
              borderColor="gray.200"
              borderWidth="1px"
            >
              <Controller
                control={control}
                name="logoBase64"
                render={({ field: { value } }) =>
                  value ? (
                    <Image
                      w="100%"
                      h="100%"
                      src={`data:image/png;base64,${value}`}
                      objectFit="scale-down"
                    />
                  ) : (
                    <></>
                  )
                }
              />
            </Box>
          </Stack>
        </Stack>
        <Stack align="center">
          <Text fontSize="15px">Choose your theme color</Text>
          <SimpleGrid columns={{ xs: 5, md: 9, xl: 12 }} spacing={1}>
            {[
              "#ffa133",
              "#ff5e5e",
              "#ff0000",
              "#da4671",
              "#945567",
              "#bc51ff",
              "#40406d",
              "#445bff",
              "#64c6ff",
              "#339195",
              "#205a4d",
              "#54c354",
            ].map((color, i) => (
              <IconButton
                key={i}
                aria-label=""
                backgroundColor={color}
                h="40px"
                w="40px"
                borderRadius="50%"
                _hover={{ backgroundColor: "none" }}
                _focusVisible={{ backgroundColor: "none" }}
                _active={{ backgroundColor: "none" }}
                _focus={{ backgroundColor: "none" }}
                onClick={() => {
                  setValue("customBranding", color, { shouldDirty: true });
                }}
              />
            ))}
          </SimpleGrid>
        </Stack>
        <Stack align="center">
          <Controller
            control={control}
            name="customBranding"
            render={({ field, fieldState: { invalid, error } }) => (
              <Input
                wrapper={{
                  minW: "100px",
                  maxW: "250px",
                }}
                input={{
                  variant: "outline",
                  type: "text",
                  placeholder: "#FFFFFF",
                  ...field,
                  isInvalid: invalid,
                }}
                right={
                  <Box
                    h="20px"
                    w="20px"
                    borderRadius="50%"
                    bgColor={field.value}
                  />
                }
                withValidation
                validationMessage={error?.message}
                label="Custom"
              />
            )}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
