import { Center, Spinner, Text, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Induction } from "../api/Induction";

export default function RedirectingPage() {
  const { templateId, locationId } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  console.log(templateId, locationId);
  useEffect(() => {
    Induction.GetInductionStatus(
      { locationId: locationId!, templateId: parseInt(templateId!) },
      (response) => {
        if (
          response.inspectionSubmitted === true &&
          response.inspectionId !== null
        ) {
          navigate(`/inductionReport/${response.inspectionId}`);
        } else if (
          response.inspectionSubmitted === false &&
          response.inspectionId !== null
        ) {
          window.location.href = `qscomply://conduct-inspection/CONTINUE/${response.inspectionId}/${locationId}`;
        } else {
          window.location.href = `qscomply://conduct-inspection/NEW/${templateId}/${locationId}`;
        }
      },
      (error) => {
        toast({
          status: "error",
          title: "Error",
          description: error,
        });
      }
    );
  }, []);
  return (
    <Center height="100vh" flexDirection="column">
      <Spinner size="xl" />
      <Text mt={4}>Redirecting, please wait...</Text>
    </Center>
  );
}
