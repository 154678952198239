import axiosInstanceInduction from "./axiosInstance-Induction";

declare module Induction {
  interface RequestForm {
    inspectionId?: number;
    fullName: string;
    email: string;
    phoneNumber: string;
  }
  interface VerifyOtpForm {
    otp: string;
  }
  interface InductionStatus {
    templateId: number;
    locationId: string;
  }
}
module Induction {
  const Induction_URL = "/iauditor-route";
  export function PostRequestForm(
    data: RequestForm,
    success: (response: any) => void,
    error: (error: any) => void
  ) {
    axiosInstanceInduction
      .post(`${Induction_URL}/get/inspection-otp`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }
  export function PostVerifyOtp(
    data: VerifyOtpForm,
    success: (response: any) => void,
    error: (error: any) => void
  ) {
    axiosInstanceInduction
      .post(`${Induction_URL}/verify/inspection-otp`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }
  export function GetInductionStatus(
    data: InductionStatus,
    success: (response: any) => void,
    error: (error: any) => void
  ) {
    axiosInstanceInduction
      .post(`${Induction_URL}/check-inspection-start`, data)
      .then(({ data }) => {
        success(data);
      })
      .catch((err) => {
        error(err?.response?.data?.message ?? err?.message);
      });
  }
}
export { Induction };
